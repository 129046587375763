import moment from 'moment';

export const formatDate = (date) => date ? date.substr(0, 10) : '';

export const momentToString = (dateMonment) => {
  if (dateMonment) {
    return dateMonment.format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '';
  }
};

export const strip = (num, precision = 12) => {
  return +parseFloat(num.toPrecision(precision));
};

// eslint-disable-next-line
export const regexMoney = /^\-?((\d+(\.\d{0,2})?)|(\d*\.\d{1,2}))$/;

export const isEmpty = (val) => (val === undefined || val == null || val.length <= 0);

// eslint-disable-next-line
export const regexAdjustment = /^[0-9]{1,6}(?:\.[0-9]{1,3})?$/;

//calculate date
export const memoDateDiff = (invoice_date, memo_day) => {
  let due_date = moment(invoice_date, "YYYY-MM-DD").add(memo_day, 'days');
  let current = moment().startOf('day');
  //Difference in number of days
  let dateDiff = due_date.diff(current, 'days')
  return dateDiff;
}

//sort by type - number or letter
export const sortByType = (a, b, key, type) => {
  if (type === 'letter') {
    let value1 = a[key] || '';
    let value2 = b[key] || '';
    return value1.localeCompare(value2);
  } else {
    return a[key] - b[key]
  }
}

//inventory - sort by shape
export const shapeSort = (a, b, sortOrder, key, type) => {
  if (type === 'letter') {
    let value1 = a[key] || '';
    let value2 = b[key] || '';
    return (
      sortOrder === 'descend' ? a.shape.localeCompare(b.shape, undefined, {sensitivity: 'base'}) || value1.localeCompare(value2) :
        b.shape.localeCompare(a.shape, undefined, {sensitivity: 'base'}) || value1.localeCompare(value2)
    )
  } else {
    return (
      sortOrder === 'descend' ? a.shape.localeCompare(b.shape, undefined, {sensitivity: 'base'}) || a[key] - b[key] :
        b.shape.localeCompare(a.shape, undefined, {sensitivity: 'base'}) || a[key] - b[key]
    )
  }
}

//key: ["a","b","c"]
//obj: {"a":"","b":"","c":""}
export const createEmptyObjByKey = (keys) => {
  let obj = {};
  for (let i = 0; i < keys.length; i++) {
    obj[keys[i]] = "";
  }
  return obj;
}

/*
           ERP      MONDAY
Ji         4        23814627
Mariam     17       23982991
Cindy      50       24462877
Elisha     45       35607022
Matt       60       40962405
Chiara     57       36742822
Madeline   47       24544538
 */
export const saleErpIdToMondayId = (erp_id) => {
  const people = [
    {erp: 4, monday: 23814627}, //Ji
    {erp: 17, monday: 23982991}, //Mariam
    {erp: 50, monday: 24462877}, //Cindy
    {erp: 45, monday: 35607022}, //Elisha
    {erp: 60, monday: 40962405}, //Matt
    {erp: 57, monday: 36742822}, //Chiara
    {erp: 47, monday: 24544538}, //Madeline
    {erp: 64, monday: 45407523}, //Chloe
    {erp: 70, monday: 64589469}, //Kaitlyn
    {erp: 76, monday: 71674560}, //Katya
    {erp: 75, monday: 71674562}, //Ashley
  ]
  const result = people.find(({erp}) => erp === erp_id);
  return result.monday;
}






