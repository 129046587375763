import React, {Component} from 'react';
import {
  Row, Col, Card, Form, DatePicker, Select, Checkbox,
  Icon, Collapse, Button, Input, message, Spin, Modal
} from "antd";
import LinkButton from "../../components/LinkButton";
import {
  reqDeleteOrder, reqGetOrderByOrderId, reqGetOrderFeeByOrderId, reqGetSalesPlusDelete,
  reqUpdateOrder, reqGetOrderInfoByOrderId, reqMoveToPurgatory, reqUpdateDiscount
} from "../../api";
import moment from 'moment';
import NoteTable from "../../components/Order/Note/NoteTable";
import PaymentTable from "../../components/Order/Payment/PaymentTable";
import {formatDate, momentToString} from "../../utils/utility";
import {ORDER_JOB_TYPE, DISCOUNT_TYPE} from "../../utils/const";
import FeeList from "../../components/Order/FeeList";
import TableComponent from '../../components/OrderItemTable';
import {ORDER_ITEM_TABLE} from '../../utils/config';
import {Link} from 'react-router-dom';
import {debounce} from 'lodash';
import {getUserType, hasRole} from '../../utils/auth';

const {Item} = Form;
const {Option} = Select;
const {Panel} = Collapse;
const {TextArea} = Input;

const DISCOUNT_PATTERN = /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.user_type = getUserType();
    this.id = this.props.match.params.id;
    this.state = {
      order: {
        discount_percent: 1,
        discount_type: ''
      },
      fee: {},
      sales: [],
      openKey: [],
      customer: {
        first_name: '',
        last_name: ''
      },
      loading: true,
      showAdd: false,
    };

  }

  componentDidMount() {
    //get order info
    reqGetOrderByOrderId(this.id)
      .then(response => {
        this.setState({
          order: response.data.order,
          fee: response.data.fee,
          openKey: response.data.openKey,
          customer: response.data.customer[0],
          loading: false
        });
      })
      .catch(error => {
        console.log(error.response);
        const data = error.response.data;
        if (data.message) {
          message.error(data.message)
        }
        this.props.history.replace('/orders/list/');
      });

    //get all sales
    reqGetSalesPlusDelete()
      .then(response => {
        const sales = response.data;
        this.setState({sales});
      })
      .catch(error => {
        console.log(error.response)
      });

  }

  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({loading: true});
        let {order_date, due_date, picked_up, discount_percent} = values;
        order_date = momentToString(order_date);
        due_date = momentToString(due_date);
        picked_up = momentToString(picked_up);
        discount_percent = discount_percent / 100;

        const {sales_representitive, payment_type, urgent, comments, job_description, discount_type} = values;

        const order = {
          sales_representitive, payment_type, urgent,
          comments, job_description, order_date, due_date, picked_up,
          discount_type, discount_percent
        };

        reqUpdateOrder(this.id, order)
          .then(response => {
            this.setState({order: response.data, loading: false});
            message.success('Updated successfully!');
            this.props.history.replace('/orders/view/' + this.id);
          })
          .catch(error => {
            console.log(error)
          });
      }
    });
  };

  setOpenKey = (openKey) => {
    this.setState({openKey});
  };

  onUpdateFee = () => {
    reqGetOrderFeeByOrderId(this.id)
      .then(response => {
        this.setState({fee: response.data});
      })
      .catch(error => {
        console.log(error)
      });
  };

  onUpdateOrderInfo = () => {
    reqGetOrderInfoByOrderId(this.id)
      .then(response => {
        this.setState({order: response.data});
      })
      .catch(error => {
        console.log(error)
      });
  };

  //Delete
  deleteOrder = (order_id) => {
    Modal.confirm({
      title: `Are you sure you want to delete this order?`,
      onOk: () => {
        reqDeleteOrder(order_id)
          .then(response => {
              this.props.history.push('/orders/list');
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  };

  //Move to Purgatory
  moveToPurgatory = (order_id) => {
    Modal.confirm({
      title: `Are you sure you want to move this order to Purgatory?`,
      onOk: () => {
        reqMoveToPurgatory(order_id)
          .then(response => {
              this.props.history.push('/orders/purgatory');
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  }

  //discount type change - discount percent change
  handleDiscountTypeChange = (value) => {
    let discount_percent, discount_type;
    discount_type = value;
    if (!value) {
      this.setState({order: {...this.state.order, discount_type: value, discount_percent: 0}});
      this.props.form.setFieldsValue({discount_percent: 0});
      discount_percent = 0;
    } else if (value === 'Credit Card') {
      let full_payment_due_day = moment().add(7, 'days').format("YYYY-MM-DD");
      let ful_payment_comments = "*Full payment due by " + full_payment_due_day + " to qualify for full payment discount.* "
        + (this.state.order.comments || "");
      this.setState({
        order: {
          ...this.state.order, discount_type: value, discount_percent: 0.02,
          comments: ful_payment_comments
        }
      });
      this.props.form.setFieldsValue({discount_percent: 2.00});
      discount_percent = 0.02;
    } else if (value === 'e-Transfer') {
      let full_payment_due_day = moment().add(7, 'days').format("YYYY-MM-DD");
      let ful_payment_comments = "*Send e-transfers to: payment@engagestudio.com. Full payment due by " + full_payment_due_day + " to qualify for full payment discount.* "
        + (this.state.order.comments || "");
      this.setState({
        order: {
          ...this.state.order, discount_type: value, discount_percent: 0.02,
          comments: ful_payment_comments
        }
      });
      this.props.form.setFieldsValue({discount_percent: 3.50});
      discount_percent = 0.035;
    } else if (value === 'Cheque') {
      let full_payment_due_day = moment().add(7, 'days').format("YYYY-MM-DD");
      let ful_payment_comments = "*Make cheque payable to 'Engage Diamond Studio'. Full payment due by " + full_payment_due_day + " to qualify for full payment discount.* "
        + (this.state.order.comments || "");
      this.setState({
        order: {
          ...this.state.order, discount_type: value, discount_percent: 0.02,
          comments: ful_payment_comments
        }
      });
      this.props.form.setFieldsValue({discount_percent: 3.50});
      discount_percent = 0.035;
    } else {
      this.setState({order: {...this.state.order, discount_type: value, discount_percent: 0.035}})
      this.props.form.setFieldsValue({discount_percent: 3.50});
      discount_percent = 0.035;
    }
    reqUpdateDiscount(this.id, discount_type, discount_percent)
      .then(response => {
        message.success('Discount Updated');
        this.onUpdateFee();
      })
      .catch(error => {
        console.log(error)
      })
  }

  //onchange discount percent input
  handleDiscountPercentChange = e => {
    const percent_type = e.target.value;
    if (DISCOUNT_PATTERN.test(percent_type)) {
      this.updateDiscount(this.id, this.state.order.discount_type, percent_type / 100);
    }
  }

  //update discount ajax
  updateDiscount = debounce((id, discount_type, discount_percent) =>
    reqUpdateDiscount(id, discount_type, discount_percent)
      .then(response => {
        this.onUpdateFee();
      })
      .catch(error => {
        console.log(error)
      }), 2000)

  render() {
    const {id} = this;
    const {customer, order, fee, sales, openKey, loading} = this.state;
    const {getFieldDecorator} = this.props.form;

    const formItemLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 11}
    };

    const genExtra = (type, key) => {
      if (!this.state.openKey.includes(key)) {
        let extra;
        if (this.state.order.job_description === "Repair") {
          if (type === 'repairs') {
            extra = <LinkButton onClick={(event) => {
              event.stopPropagation();
              this.setState(prevState => ({openKey: [...prevState.openKey, key]}));
              this.setState({showAdd: key});
            }}>{`Add ${type}`}</LinkButton>;
          } else if (type === 'components') {
            extra = <LinkButton onClick={(event) => {
              event.stopPropagation();
              this.setState(prevState => ({openKey: [...prevState.openKey, key]}));
              this.setState({showAdd: key});
            }}>{`Add ${type}`}</LinkButton>;
          } else {
            extra = <LinkButton></LinkButton>;
          }
        } else {
          extra = type !== 'repairs' ? <LinkButton onClick={(event) => {
            event.stopPropagation();
            this.setState(prevState => ({openKey: [...prevState.openKey, key]}));
            this.setState({showAdd: key});
          }}>{`Add ${type}`}</LinkButton> : <LinkButton></LinkButton>
        }
        return extra;
      }
    }

    return (
      <div className="order-view">
        <Spin spinning={loading} size="large">

          <div style={{padding: '10px', fontSize: '18px'}}>
            <span>
            <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
            </LinkButton>
            </span>
            <span>
              <Link to={'/customers/edit/' + customer.id}>
                <LinkButton>{customer.first_name + ' ' + customer.last_name}</LinkButton>
              </Link>
            </span>

            <div style={{float: 'right', fontSize: '18px'}}>

              <Link to={'/orders/view/' + order.id}>
                <Button style={{marginLeft: '5px'}} onClick={() => this.props.history.push('/orders/view/' + this.id)}>
                  Print Order
                </Button>
              </Link>
              {hasRole(this.user_type, ['super admin', 'production']) &&
              (<Button style={{marginLeft: '5px'}} onClick={() => this.deleteOrder(this.id)}>
                Delete Order
              </Button>)}
              {hasRole(this.user_type, ['super admin']) &&
              (<Button style={{marginLeft: '5px'}} onClick={() => this.moveToPurgatory(this.id)}>
                Move to Purgatory
              </Button>)}

            </div>
          </div>

          <Form {...formItemLayout} >
            <div style={{background: '#ECECEC', padding: '15px'}}>
              <Card title={`Order# ${order.order_number}`} bordered={false} size="small">
                <Row gutter={24}>
                  <Col span={12}>
                    <Item label="Order Date">
                      {
                        getFieldDecorator('order_date', {
                          initialValue: moment(order.order_date),
                          rules: [
                            {required: true, message: 'Date is required'}
                          ]
                        })(<DatePicker/>)
                      }
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Due Date">
                      {
                        getFieldDecorator('due_date', {
                          initialValue: moment(order.due_date),
                          rules: [
                            {required: true, message: 'Due Date is required'}
                          ]
                        })(<DatePicker/>)
                      }
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label='Sales'>
                      {
                        getFieldDecorator('sales_representitive', {
                          initialValue: order.sales_representitive,
                          rules: [
                            {required: true, message: 'Sales is required'}
                          ]
                        })(
                          <Select
                            showSearch
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                          >
                            {
                              sales.map(item => <Option
                                hidden={!(!item.deleted_at && !!item.is_active && !!item.is_sales_rep)} value={item.id}
                                key={item.id}> {item.full_name} </Option>)
                            }
                          </Select>)}
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Payment Type">
                      {getFieldDecorator('payment_type', {
                        initialValue: order.payment_type,
                        rules: [
                          {required: true, message: 'Type is required'}
                        ]
                      })(
                        <Select>
                          <Option value="cad">CAD</Option>
                          <Option value="usd">USD</Option>
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={13}>
                    <Item label="Job Description">
                      {getFieldDecorator('job_description', {
                        initialValue: order.job_description,
                        rules: [
                          {required: true, message: 'Job Description is required'}
                        ]
                      })(
                        <Select>
                          {
                            ORDER_JOB_TYPE.map(item => <Option value={item} key={item}> {item} </Option>)
                          }
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={7}>
                    <Item label="Urgent">
                      {getFieldDecorator('urgent', {
                        initialValue: order.urgent,
                        valuePropName: 'checked',
                      })(
                        <Checkbox></Checkbox>
                      )}
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label='Status'>
                      <span className="ant-form-text cy-order-status">{order.status}</span>
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Picked Up">
                      {
                        getFieldDecorator('picked_up', {
                          initialValue: !order.picked_up ? null : moment(order.picked_up)
                        })(
                          order.status === 'Ready For Pickup' ? (
                            <DatePicker/>
                          ) : order.status === 'Completed' ? (
                            <span className="ant-form-text">{formatDate(order.picked_up)}</span>
                          ) : (
                            <span className="ant-form-text">No</span>
                          )
                        )
                      }
                    </Item>
                  </Col>
                </Row>
              </Card>
            </div>

            <Collapse activeKey={openKey} onChange={this.setOpenKey}>
              {ORDER_ITEM_TABLE.map(item => {
                return (
                  <Panel key={item.key} header={item.title} extra={genExtra(item.type, item.key)}>
                    <TableComponent
                      showAdd={this.state.showAdd === item.key}
                      columns={item.columns}
                      type={item.type}
                      id={this.id}
                      onUpdateFee={this.onUpdateFee}
                      onUpdateOrderInfo={this.onUpdateOrderInfo}
                      status={order.status}
                      job_description={order.job_description}
                    />
                  </Panel>
                )
              })}
            </Collapse>

            <div style={{padding: '15px'}}>
              <Row gutter={24}>
                <Col span={10}>
                  <Row>
                    <Col span={12}>
                      <Item label="Discount" labelCol={{span: 7}} wrapperCol={{span: 15}}>
                        {getFieldDecorator('discount_type', {
                          initialValue: order.discount_type,
                        })(
                          <Select onChange={this.handleDiscountTypeChange}>
                            <Option value=''> -- </Option>
                            {DISCOUNT_TYPE.map(item => <Option value={item} key={item}> {item} </Option>)}
                          </Select>
                        )}
                      </Item>
                    </Col>
                    <Col span={11}>
                      <Item>
                        {getFieldDecorator('discount_percent', {
                          initialValue: (order.discount_percent * 100).toFixed(2),
                          rules: [{pattern: DISCOUNT_PATTERN, message: '0-100 Only',}],
                        })(
                          <Input onChange={this.handleDiscountPercentChange} addonAfter="%"/>
                        )}
                      </Item>
                    </Col>
                  </Row>


                  <Item label='Comments' labelCol={{span: 24}} wrapperCol={{span: 24}}>
                    {
                      getFieldDecorator('comments', {
                        initialValue: order.comments,
                      })(
                        <TextArea rows={8}/>
                      )
                    }
                  </Item>

                </Col>
                <Col span={14}>
                  <FeeList fee={fee} id={id} displayAdd={true}/>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <NoteTable id={id}/>
                </Col>
                <Col span={12}>
                  <PaymentTable id={id} onUpdateFee={this.onUpdateFee} displayAdd={true}/>
                </Col>
              </Row>
            </div>
            <div style={{textAlign: 'center', paddingBottom: '13px'}}>
              <Button onClick={() => this.onSubmit()} type="primary" htmlType="submit">Save Changes</Button>
            </div>

          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create()(Edit);